<sk-banner-container></sk-banner-container>
<header sk-header [showCommunityLink]="true"></header>
<section class="sk-flex-page h-100">
    <nav sk-platform-navigation class="sk-left-pane"></nav>
    <div class="panel-xs-1 sk-panel-left sk-panel-primary">
        <div class="sk-panel-body">
            <h3 class="pl-200 py-100">Settings</h3>
            <div class="sk-panel-nav">
                <ul class="nav sk-nav-vert flex-column">
                    <li class="nav-item">
                        <a class="nav-link" [href]="portalUri+'settings/profile'">
                            Profile
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [href]="portalUri+'settings/account'">
                            Account
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [href]="portalUri+'settings/users'">
                            Users
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" [href]="portalUri+'settings/integrations'">
                            Integrations
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [href]="portalUri+'settings/notifications'">
                            Notifications
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <main class="sk-content-pane">
        <router-outlet [class.d-none]="loading$ | async"></router-outlet>
    </main>
    <div *ngIf="loading$ | async" class="d-flex flex-column justify-content-start align-items-center w-100 mt-5">
        <div class="sk-spin-loader"></div>
        <div class="loading-text d-flex flex-column align-items-center mt-4">
            <h2>Loading...</h2>
            <p>Please wait while the system processes your request.</p>
        </div>
    </div>
</section>
