import { Inject, Injectable } from '@angular/core';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { RouteResolverService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

import { WindowToken } from './window';

@Injectable({
    providedIn: 'root'
})
export class InitializationService {

    constructor(
        private userProvider: AbstractUserProvider,
        private routeResolver: RouteResolverService,
        @Inject(WindowToken) private window: Window
    ) { }

    async run(): Promise<void> {
        await this.ensureUserLoggedIn();

        datadogLogs.onReady(() => {
            const user = this.userProvider.getCurrentUser();

            datadogLogs.init({
                clientToken: 'pub434a1bde2b22e5d801ef6882d5f11a03',
                service: 'pci_web',
                env: `${this.getEnv()}`,
            });

            datadogLogs.setGlobalContextProperty('application', 'cloud_manager');
            datadogLogs.setGlobalContextProperty('environment', this.getEnv());

            datadogLogs.setUser({
                id: user.userId,
                email: user.email,
                name: user.fullName,
                partnerId: user.partnerId,
                partnerName: user.partnerName,
                isTestPartner: user.isTestPartner,
            });
        });

        datadogRum.onReady(() => {
            if (this.getEnv() === 'prod') {
                const user = this.userProvider.getCurrentUser();

                datadogRum.init({
                    applicationId: '9ce59d4c-1a41-42bf-aca7-f7d186355884',
                    clientToken: 'pub434a1bde2b22e5d801ef6882d5f11a03',
                    service: 'pci_web',
                    env: `${this.getEnv()}`,
                    sessionSampleRate: 100,
                    sessionReplaySampleRate: 100,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    defaultPrivacyLevel: 'mask-user-input',
                    allowedTracingUrls: [
                        {
                            match: (url) => url.includes('skykick.com'),
                            propagatorTypes: ['tracecontext'],
                        },
                    ],
                });

                datadogRum.setGlobalContextProperty('application', 'cloud_manager');
                datadogRum.setGlobalContextProperty('environment', this.getEnv());

                datadogRum.setUser({
                    id: user.userId,
                    email: user.email,
                    name: user.fullName,
                    partnerId: user.partnerId,
                    partnerName: user.partnerName,
                    isTestPartner: user.isTestPartner,
                });

                datadogRum.startSessionReplayRecording();
            }
        });
    }

    private getEnv() {
        if (this.window.location.hostname.includes('localhost')) {
            return 'local';
        }

        const tld = this.routeResolver.parseTopLevelDomain();
        return tld.includes('com') ? 'prod' : this.window.location.hostname.split('.')[0].replace('pci-', '');
    }

    private ensureUserLoggedIn() {
        return new Promise<void>((resolve, reject) => {
            const portalPageThatWillRedirectToLoginPage = this.getLoginRedirect();
            try {
                // We used to check the beta flag on the user, but that is now done downstream.
                // I'm leaving the user retrieval call since it may throw an exception if the user is not logged in.
                this.userProvider.getCurrentUser();
                resolve();
            } catch {
                // The currently logged in user doesn't exist or their session has expired
                this.window.location.href = portalPageThatWillRedirectToLoginPage;
                reject(new Error('User is not logged into the portal'));
            }
        });
    }

    private getLoginRedirect(): string {
        const currentUrl = this.window.location.href;
        const encodedCurrentUrl = encodeURIComponent(currentUrl);

        let loginRedirectUrl = 'https://portal.skykick.com';

        if (currentUrl.includes('pci-dev')) {
            loginRedirectUrl = 'https://auth.skykick.dev/login';
        } else if (currentUrl.includes('pci-qa')) {
            loginRedirectUrl = 'https://auth-qa.skykick.dev/login';
        }

        return `${loginRedirectUrl}?return_to=${encodedCurrentUrl}`;
    }
}
